import apple from '$res/css/images/btn_apple_sign_in.svg';
import google from '$res/css/images/btn_google_sign_in.png';
import sso from '$res/css/images/sso.svg';
import { Image } from '@/components/common/Image/Image';
import { Paragraph } from '@/components/common/Paragraph/Paragraph';
import { Button } from '@/components/common/buttons/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { routes } from '@/utils/routes/routes';
import { config } from '@/config';

export const SocialLoginButtons = () => {
  const { translate } = useTranslation();
  const { isIsv, enterpriseSaml } = config;

  if (isIsv && !enterpriseSaml) {
    return null;
  }

  return (
    <>
      <Paragraph className={'-my-4! text-xs text-center font-medium'}>{translate('SignUp.or')}</Paragraph>
      <div className={'flex flex-col gap-12'}>
        {isIsv || (
          <>
            <Button
              href={routes.TC_AUTH_GOOGLE.url()}
              variant={'outline'}
              color={'gray'}
              icon={<Image src={google} className={'mb-3 h-16'} />}
            >
              {translate('Auth.google.title')}
            </Button>
            <Button
              href={routes.TC_AUTH_APPLE.url()}
              variant={'outline'}
              color={'gray'}
              icon={<Image src={apple} className={'dark:brightness-0 dark:invert-100 mb-3 h-16'} />}
            >
              {translate('Auth.apple.title')}
            </Button>
          </>
        )}
        {enterpriseSaml && (
          <Button
            href={routes.TC_AUTH_SSO.url()}
            variant={'outline'}
            color={'gray'}
            icon={<Image src={sso} className={'dark:brightness-0 dark:invert-100 h-16'} />}
          >
            {translate('Auth.sso.title')}
          </Button>
        )}
      </div>
    </>
  );
};
